<template>
  <div class="login-form">
    <div class="welcome-text">
      Password Reset: {{ this.$route.params.token }}
    </div>
    <form name="form" @submit.prevent="handleReset">
      <div class="underline-input">
        <!--          <label for="email">Email</label>-->
        <input
            v-model="email"
            v-validate="'required'"
            type="text"
            placeholder="Username"
            class="form-control"
            name="email"
        />
      </div>
      <div
          v-if="errors.has('email')"
          class="alert alert-danger"
          role="alert"
      >
        Email is required!
      </div>
      <div class="underline-input">
        <!--          <label for="password">Password</label>-->
        <input
            v-model="password"
            v-validate="'required'"
            type="password"
            placeholder="Password"
            class="form-control"
            name="password"
        />
      </div>
      <div
          v-if="errors.has('password')"
          class="alert alert-danger"
          role="alert"
      >
        Password is required!
      </div>
      <div class="underline-input">
        <!--          <label for="password">Password</label>-->
        <input
            v-model="password_check"
            v-validate="'required'"
            type="password"
            placeholder="Password"
            class="form-control"
            name="password"
        />
      </div>
      <div
          v-if="errors.has('password_check')"
          class="alert alert-danger"
          role="alert"
      >
        Password is required!
      </div>
      <Loading :is_loading="loading" class="loading"/>
      <div class="flex-row-centered">
        <button class="rebutton" :disabled="loading">
          <span>Reset your password</span>
        </button>
      </div>
      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>
    </form>
    <div class="footer">
      <router-link to="/terms">Terms of Use</router-link>
      |
      <router-link to="/privacy">Privacy Policy</router-link>
    </div>
  </div>
</template>

<script>
import Loading from "./Loading";
import UserService from "@/services/user.service";
import {tokenizer} from "acorn";

export default {
  name: 'Login',
  components: {
    Loading
  },
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      message: ''
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push('/home');
    }
  },
  methods: {
    tokenizer,
    handleReset() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.email && this.password) {
          UserService.sendUpdatedPassword(this.email, this.password, this.$route.params.token)
              .then(
                  () => {
                    this.$router.push('/home');
                  },
                  (error) => {
                    this.loading = false;
                    this.message = error.response.data.message;
                  },
              );
        }
      });
    },
  },
};
</script>

<style scoped>

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10%
}

.flex-row-space-between {
  margin-top: 4em;
}

.flex-row-centered {
  margin-top: 2em;
}

.welcome-text {
  text-align: center;
  font-weight: bold;
  color: #026cc0;
  margin-bottom: 10%;
}

label {
  display: block;
  margin-top: 10px;
}

.footer {
  display: block;
  position: fixed;
  left: 75%;
  transform: translate(-50%, 0);
  bottom: 7.5%;
  text-align: center;
  font-size: 0.75em;
}

.loading {
  font-size: 1em;
}

</style>
